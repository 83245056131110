import {
  FleetDashboardProvider,
  GridItem,
  useFleetDashboard
} from "./FleetDashboardContext";
import { CSSProperties, FC, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import Pages from "enums/Pages";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import InnerPageLayout from "layouts/InnerPageLayout";
import { useTranslation } from "react-i18next";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Button } from "@mui/material";
import { Plus, Edit, Save } from "react-feather";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const FleetDashboardContent: FC = () => {
  const { items, addCard, removeItem, updateLayout } = useFleetDashboard();
  const { setPageTitle, setLocation } = usePageLocation();
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);

  // useEffect(() => {
  //   const fetchFleetData = async () => {
  //     setIsLoading(true);
  //     try {
  //       // Fetch Firmware Items
  //       const firmwareResponse = await FleetAPI.listFirmware({
  //         page: 1,
  //         page_size: 10
  //       });
  //       firmwareResponse.forEach(item => {
  //         addCard({
  //           type: "firmware",
  //           data: item
  //         });
  //       });

  //       // Fetch Camera Groups
  //       const cameraGroupResponse = await FleetAPI.listCameraGroup({
  //         page: 1,
  //         page_size: 10
  //       });
  //       cameraGroupResponse.camera_group.forEach(group => {
  //         addCard({
  //           type: "cameraGroup",
  //           data: group
  //         });
  //       });

  //       // Fetch Firmware Schedules
  //       const firmwareScheduleResponse = await FleetAPI.listFirmwareSchedule({
  //         page: 1,
  //         page_size: 10
  //       });
  //       firmwareScheduleResponse.firmware_schedule.forEach(schedule => {
  //         addCard({
  //           type: "firmwareSchedule",
  //           data: schedule
  //         });
  //       });
  //     } catch (error) {
  //       errorHandler({ error });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   // fetchFleetData();
  //   console.log(fetchFleetData);
  // }, [sessionUser]);

  useEffect(() => {
    setPageTitle(t("windowTitle.fleetDashboard"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.system")
      },
      {
        label: t("FleetManagement.title"),
        page: Pages.FLEET
      }
    ]);
  }, [t, setPageTitle, setLocation]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const gridWrapper = (el: GridItem): JSX.Element => {
    const removeStyle: CSSProperties = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
      visibility: isEditMode ? "visible" : "hidden"
    };
    const dragStyle: CSSProperties = {
      position: "absolute",
      left: "2px",
      top: 0,
      cursor: "grab",
      visibility: isEditMode ? "visible" : "hidden"
    };

    const layout = {
      i: el.i,
      x: el.x,
      y: el.y,
      w: el.w,
      h: el.h
    };

    return (
      <div key={el.i} data-grid={layout} style={{ border: "1px solid black" }}>
        {el.component}
        <span
          className="drag-handle"
          style={dragStyle}
          title="Drag to rearrange"
        >
          &#x2630;
        </span>
        <span
          className="remove"
          style={removeStyle}
          onClick={() => removeItem(el.i)}
        >
          &times;
        </span>
      </div>
    );
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "20px",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <Button color="primary" onClick={addItem}>
              <Plus size={16} /> {t("FleetManagement.addItem")}
            </Button> */}

            {/* <Button color="primary" onClick={() => setOpenCameraGroupDialog(true)}>
              <Plus size={16} /> {t("FleetManagement.addCameraGroup")}
            </Button>
            <Button color="primary" onClick={() => setOpenFirmwareDialog(true)}>
              <Plus size={16} /> {t("FleetManagement.addFirmware")}
            </Button>
            <Button color="primary" onClick={() => setOpenFirmwareScheduleDialog(true)}>
              <Plus size={16} /> {t("FleetManagement.addFirmwareSchedule")}
            </Button> */}

            {/* <Button color="primary" onClick={() => addCard({ type: "map" })}>
              <Plus size={16} /> {t("FleetManagement.addMap")}
            </Button> */}

            <Button
              color="primary"
              onClick={() => addCard({ type: "table" })}
              disabled={!isEditMode}
            >
              <Plus size={16} /> {t("FleetManagement.addTable")}
            </Button>

            {/* <Button color="primary" onClick={() => addCard({ type: "camera" })}>
              <Plus size={16} /> {t("FleetManagement.addCamera")}
            </Button> */}
          </div>

          <Button
            color={isEditMode ? "secondary" : "primary"}
            onClick={toggleEditMode}
            startIcon={isEditMode ? <Save /> : <Edit />}
            variant="contained"
          >
            {isEditMode ? t("action.save") : t("action.edit")}
          </Button>
        </div>

        {/* Dialogs
        {openCameraGroupDialog && (
          <CameraGroupDialog
            open={openCameraGroupDialog}
            onClose={() => setOpenCameraGroupDialog(false)}
            onSuccess={newItem => {
              addCard({ type: "cameraGroup", data: newItem });
            }}
          />
        )}
        {openFirmwareDialog && (
          <FirmwareDialog
            open={openFirmwareDialog}
            onClose={() => setOpenFirmwareDialog(false)}
            onSuccess={newItem => {
              addCard({ type: "firmware", data: newItem });
            }}
          />
        )}
        {openFirmwareScheduleDialog && (
          <FirmwareScheduleDialog
            open={openFirmwareScheduleDialog}
            onClose={() => setOpenFirmwareScheduleDialog(false)}
            onSuccess={newItem => {
              addCard({ type: "firmwareSchedule", data: newItem });
            }}
          />
        )} */}

        <ResponsiveReactGridLayout
          className="layout"
          layouts={{ lg: items }}
          rowHeight={100}
          onLayoutChange={updateLayout}
          isResizable={isEditMode}
          isDraggable={isEditMode}
          draggableHandle=".drag-handle, .text"
          draggableCancel=".add"
        >
          {items.map(el => {
            const created = gridWrapper(el);
            return created;
          })}
        </ResponsiveReactGridLayout>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

const FleetManagement: FC = () => (
  <FleetDashboardProvider>
    <FleetDashboardContent />
  </FleetDashboardProvider>
);

export default FleetManagement;
