import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type listFleetParams = {
  page: number;
  page_size: number;
};

export type FleetItem = {
  index: string;
  date_insert: string;
  name: string;
  alias: string;
  description: string;
  bucket: string;
  firmware_path: string;
};

export type FleetResponse = {
  data: {
    total_items: number;
    total_pages: number;
    page: number;
    items: FleetItem[];
  };
};

export type FirmwareCreateParams = {
  name: string;
  alias: string;
  description: string;
  filename: string;
};

export type FirmwareCreateResponse = {
  success: string;
  index: string;
  url: string;
};

export type FirmwareUpdateParams = {
  index: string;
  name?: string;
  alias?: string;
  description?: string;
  filename?: string;
};

export type FirmwareUpdateResponse = {
  success: string;
  index?: string;
  url?: string;
};

export type FirmwareDeleteParams = {
  index: string;
};

export type FirmwareDeleteResponse = {
  success: string;
};

export type FirmwareGetParams = {
  index: string;
};

export type FirmwareItem = {
  index: string;
  date_insert: string;
  name: string;
  alias: string;
  description: string;
  bucket: string;
  firmware_path: string;
};

export type FirmwareListParams = {
  page: number;
  page_size: number;
};

export type FirmwareListResponse = {
  data: {
    total_items: number;
    total_pages: number;
    page: number;
    items: FirmwareItem[];
  };
};

export type CameraGroupCreateParams = {
  group_name: string;
  cameras: string[];
};

export type CameraGroupCreateResponse = {
  success: string;
  index: string;
};

export type CameraGroupUpdateParams = {
  index: string;
  group_name?: string;
  cameras?: string[];
};

export type CameraGroupUpdateResponse = {
  success: string;
};

export type CameraGroupDeleteParams = {
  index: string;
};

export type CameraGroupDeleteResponse = {
  success: string;
};

export type CameraGroupGetParams = {
  index: string;
};

export type CameraGroupItem = {
  index: string;
  name: string;
  cameras: string[];
};

export type CameraGroupListParams = {
  page: number;
  page_size: number;
};

export type CameraGroupListResponse = {
  total_items: number;
  total_pages: number;
  page: number;
  camera_group: CameraGroupItem[];
};

export type FirmwareScheduleCreateParams = {
  name: string;
  description: string;
  date_schedule: string;
  firmware_index: string;
  camera_group_index?: string[];
  cameras?: string[];
};

export type FirmwareScheduleCreateResponse = {
  success: string;
  index: string;
};

export type FirmwareScheduleUpdateParams = {
  index: string;
  name?: string;
  description?: string;
  date_schedule?: string;
  firmware_index?: string;
  camera_group_index?: string[];
  cameras?: string[];
};

export type FirmwareScheduleUpdateResponse = {
  success: string;
};

export type FirmwareScheduleDeleteParams = {
  index: string;
};

export type FirmwareScheduleDeleteResponse = {
  success: string;
};

export type FirmwareScheduleGetParams = {
  index: string;
};

export type FirmwareScheduleItem = {
  index: string;
  name: string;
  description: string;
  date_execute: string;
  camera_group: string[];
  cameras: string[];
};

export type FirmwareScheduleListParams = {
  page: number;
  page_size: number;
};

export type FirmwareScheduleListResponse = {
  total_items: number;
  total_pages: number;
  page: number;
  firmware_schedule: FirmwareScheduleItem[];
};

export interface AddFirmwareCardParams {
  type: "firmware";
  data: FleetItem;
}

export interface AddCameraGroupCardParams {
  type: "cameraGroup";
  data: CameraGroupItem;
}

export interface AddFirmwareScheduleCardParams {
  type: "firmwareSchedule";
  data: FirmwareScheduleItem;
}

export interface AddMapWidgetParams {
  type: "map";
  data?: FirmwareScheduleItem;
}

export interface AddTableWidgetParams {
  type: "table";
  data?: FirmwareScheduleItem;
}

export interface AddCameraWidgetParams {
  type: "camera";
  data?: FirmwareScheduleItem;
}

export type AddCardParams =
  | AddFirmwareCardParams
  | AddCameraGroupCardParams
  | AddFirmwareScheduleCardParams
  | AddMapWidgetParams
  | AddTableWidgetParams
  | AddCameraWidgetParams;

type UseFleetAPIType = {
  createFirmware: (
    params: FirmwareCreateParams
  ) => Promise<FirmwareCreateResponse>;
  getFirmware: (params: FirmwareGetParams) => Promise<FirmwareItem>;
  deleteFirmware: (
    params: FirmwareDeleteParams
  ) => Promise<FirmwareDeleteResponse>;
  listFirmware: (params: FirmwareListParams) => Promise<FirmwareItem[]>;
  updateFirmware: (
    params: FirmwareUpdateParams
  ) => Promise<FirmwareUpdateResponse>;

  createCameraGroup: (
    params: CameraGroupCreateParams
  ) => Promise<CameraGroupCreateResponse>;
  getCameraGroup: (params: CameraGroupGetParams) => Promise<CameraGroupItem>;
  deleteCameraGroup: (
    params: CameraGroupDeleteParams
  ) => Promise<CameraGroupDeleteResponse>;
  listCameraGroup: (
    params: CameraGroupListParams
  ) => Promise<CameraGroupListResponse>;
  updateCameraGroup: (
    params: CameraGroupUpdateParams
  ) => Promise<CameraGroupUpdateResponse>;

  createFirmwareSchedule: (
    params: FirmwareScheduleCreateParams
  ) => Promise<FirmwareScheduleCreateResponse>;
  getFirmwareSchedule: (
    params: FirmwareScheduleGetParams
  ) => Promise<FirmwareScheduleItem>;
  deleteFirmwareSchedule: (
    params: FirmwareScheduleDeleteParams
  ) => Promise<FirmwareScheduleDeleteResponse>;
  listFirmwareSchedule: (
    params: FirmwareScheduleListParams
  ) => Promise<FirmwareScheduleListResponse>;
  updateFirmwareSchedule: (
    params: FirmwareScheduleUpdateParams
  ) => Promise<FirmwareScheduleUpdateResponse>;
};

const useFleetAPI = (): UseFleetAPIType => {
  const API = useAxios();

  const createFirmware = async (
    params: FirmwareCreateParams
  ): Promise<FirmwareCreateResponse> => {
    const response: AxiosResponse<FirmwareCreateResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_CREATE, params);
    return response.data;
  };

  const getFirmware = async (
    params: FirmwareGetParams
  ): Promise<FirmwareItem> => {
    const response: AxiosResponse<FirmwareItem> = await API.instance.get(
      APIEndpoints.FIRMWARE_GET,
      { params }
    );
    return response.data;
  };

  const deleteFirmware = async (
    params: FirmwareDeleteParams
  ): Promise<FirmwareDeleteResponse> => {
    const response: AxiosResponse<FirmwareDeleteResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_DELETE, params);
    return response.data;
  };

  const listFirmware = async (
    params: FirmwareListParams
  ): Promise<FirmwareItem[]> => {
    const response: AxiosResponse<FirmwareListResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_LIST, params);
    return response.data.data.items;
  };

  const updateFirmware = async (
    params: FirmwareUpdateParams
  ): Promise<FirmwareUpdateResponse> => {
    const response: AxiosResponse<FirmwareUpdateResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_UPDATE, params);
    return response.data;
  };

  const createCameraGroup = async (
    params: CameraGroupCreateParams
  ): Promise<CameraGroupCreateResponse> => {
    const response: AxiosResponse<CameraGroupCreateResponse> =
      await API.instance.post(
        APIEndpoints.FIRMWARE_CAMERA_GROUP_CREATE,
        params
      );
    return response.data;
  };

  const getCameraGroup = async (
    params: CameraGroupGetParams
  ): Promise<CameraGroupItem> => {
    const response: AxiosResponse<CameraGroupItem> = await API.instance.get(
      APIEndpoints.FIRMWARE_CAMERA_GROUP_GET,
      { params }
    );
    return response.data;
  };

  const deleteCameraGroup = async (
    params: CameraGroupDeleteParams
  ): Promise<CameraGroupDeleteResponse> => {
    const response: AxiosResponse<CameraGroupDeleteResponse> =
      await API.instance.post(
        APIEndpoints.FIRMWARE_CAMERA_GROUP_DELETE,
        params
      );
    return response.data;
  };

  const listCameraGroup = async (
    params: CameraGroupListParams
  ): Promise<CameraGroupListResponse> => {
    const response: AxiosResponse<CameraGroupListResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_CAMERA_GROUP_LIST, params);
    return response.data;
  };

  const updateCameraGroup = async (
    params: CameraGroupUpdateParams
  ): Promise<CameraGroupUpdateResponse> => {
    const response: AxiosResponse<CameraGroupUpdateResponse> =
      await API.instance.post(
        APIEndpoints.FIRMWARE_CAMERA_GROUP_UPDATE,
        params
      );
    return response.data;
  };

  const createFirmwareSchedule = async (
    params: FirmwareScheduleCreateParams
  ): Promise<FirmwareScheduleCreateResponse> => {
    const response: AxiosResponse<FirmwareScheduleCreateResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_SCHEDULE_CREATE, params);
    return response.data;
  };

  const getFirmwareSchedule = async (
    params: FirmwareScheduleGetParams
  ): Promise<FirmwareScheduleItem> => {
    const response: AxiosResponse<FirmwareScheduleItem> =
      await API.instance.get(APIEndpoints.FIRMWARE_SCHEDULE_GET, { params });
    return response.data;
  };

  const deleteFirmwareSchedule = async (
    params: FirmwareScheduleDeleteParams
  ): Promise<FirmwareScheduleDeleteResponse> => {
    const response: AxiosResponse<FirmwareScheduleDeleteResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_SCHEDULE_DELETE, params);
    return response.data;
  };

  const listFirmwareSchedule = async (
    params: FirmwareScheduleListParams
  ): Promise<FirmwareScheduleListResponse> => {
    const response: AxiosResponse<FirmwareScheduleListResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_SCHEDULE_LIST, params);
    return response.data;
  };

  const updateFirmwareSchedule = async (
    params: FirmwareScheduleUpdateParams
  ): Promise<FirmwareScheduleUpdateResponse> => {
    const response: AxiosResponse<FirmwareScheduleUpdateResponse> =
      await API.instance.post(APIEndpoints.FIRMWARE_SCHEDULE_UPDATE, params);
    return response.data;
  };

  return {
    createFirmware,
    getFirmware,
    deleteFirmware,
    listFirmware,
    updateFirmware,
    createCameraGroup,
    getCameraGroup,
    deleteCameraGroup,
    listCameraGroup,
    updateCameraGroup,
    createFirmwareSchedule,
    getFirmwareSchedule,
    deleteFirmwareSchedule,
    listFirmwareSchedule,
    updateFirmwareSchedule
  };
};

export default useFleetAPI;
