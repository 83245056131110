import { FC } from "react";
import {
  Box,
  styled,
  IconButton,
  Grid,
  Divider,
  Typography,
  Stack
} from "@mui/material";
import { X } from "react-feather";

import { EquipmentLocation } from "api/MapsAPI";
import { useTranslation } from "react-i18next";

const Container = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "50%",
  left: "25%",
  transform: "translate(-50%, -50%)",
  boxShadow: theme.shadows["16"],
  width: "215px",
  borderRadius: 8,
  zIndex: theme.zIndex.modal,
  backgroundColor: theme.palette.background.paper,
  fontSize: "10px"
}));

const Content = styled(Box)(({ theme }) => ({
  padding: "0 8px 8px",
  overflowY: "auto",
  fontSize: "10px",
  "& .MuiGrid-item": {
    padding: "0 8px"
  },
  "& .MuiTypography-caption:not(.equipmentStatus)": {
    color: theme.palette.primary.light
  },
  "& .camerasList": {
    maxHeight: "300px",
    overflowY: "auto"
  },
  "& .camerasList > div": {
    padding: "8px 0",
    "& > div": {
      paddingLeft: "10px"
    }
  }
}));

type Props = {
  onClose?: () => void;
  equipment: EquipmentLocation;
  statusColor: string;
};

const EquipmentDetails: FC<Props> = ({ onClose, equipment, statusColor }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <strong>{t("EquipmentMapPage.equipment")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <strong>{t("EquipmentMapPage.status.label")}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              padding: "0",
              height: "24px",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <IconButton
              style={{
                backgroundColor: statusColor,
                borderRadius: "0",
                color: "white",
                padding: "0",
                width: "24px",
                maxWidth: "24px"
              }}
              onClick={onClose}
            >
              <X />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="caption"
              className="equipmentStatus"
              style={{ fontSize: "12px" }}
            >
              {equipment["location_name"]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="caption"
              className="equipmentStatus"
              style={{ fontSize: "12px" }}
            >
              {t(`EquipmentMapPage.status.${equipment.status}`)}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <strong>{t("EquipmentMapPage.latitude")}</strong>
            </Typography>
            <Typography variant="caption" style={{ fontSize: "12px" }}>
              {equipment.latitude}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <strong>{t("EquipmentMapPage.longitude")}</strong>
            </Typography>
            <Typography variant="caption" style={{ fontSize: "12px" }}>
              {equipment.longitude}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          sx={{ padding: "16px 16px 0 16px", fontSize: "12px" }}
        >
          <strong>{t("EquipmentMapPage.cameras")}</strong>
        </Typography>
        <Stack divider={<Divider />} className="camerasList">
          {equipment?.["camera_data"]?.map((cameraData, index) => (
            <Grid container key={`${index}-${equipment["location_name"]}`}>
              <Grid item xs={8}>
                <Typography variant="caption">
                  {cameraData.camera_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" style={{ textAlign: "right" }}>
                  {t(
                    `EquipmentMapPage.cameraStatus.${
                      cameraData.active ? "active" : "inactive"
                    }`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Content>
    </Container>
  );
};

export default EquipmentDetails;
