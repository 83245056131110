import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect
} from "react";
import { Layout } from "react-grid-layout";
import { cameraGridItem } from "./Widgets/CameraWidget/CameraWidget";
import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { format } from "date-fns";
import useFleetAPI, {
  AddCardParams,
  FleetItem,
  CameraGroupItem,
  FirmwareScheduleItem
} from "api/FleetAPI";
import MapWidget from "./Widgets/MapWidget/MapWidget";
import TableWidget, { tableConfig } from "./Widgets/TableWidget/TableWidget";
import useFleetDashboardAPI from "./FleetDashboardAPI";
import { useAuth } from "contexts/AuthContext";

export interface GridItem extends Layout {
  component: JSX.Element | ReactNode;
  widgetData?: tableConfig | any;
}

const FleetCard: React.FC<{ item: FleetItem }> = ({ item }) => {
  const { deleteFirmware } = useFleetAPI();

  const handleDelete = async () => {
    try {
      await deleteFirmware({ index: item.index });
    } catch (error) {
      console.error("Failed to delete firmware:", error);
    }
  };

  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          Firmware Index: {item.index}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {item.alias}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Version: {item.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Description: {item.description}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Added: {format(new Date(item.date_insert), "PPP")}
        </Typography>
        <Button variant="outlined" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </CardContent>
    </Card>
  );
};

export const createFleetGridItem = (item: FleetItem): GridItem => ({
  i: `f-${item.index}`,
  x: 1,
  y: Infinity,
  w: 4,
  h: 2,
  component: <FleetCard item={item} />
});

const CameraGroupCard: React.FC<{ item: CameraGroupItem }> = ({ item }) => {
  const { deleteCameraGroup } = useFleetAPI();

  const handleDelete = async () => {
    try {
      await deleteCameraGroup({ index: item.index });
    } catch (error) {
      console.error("Failed to delete camera group:", error);
    }
  };

  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          Group Index: {item.index}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {item.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Cameras: {item.cameras.join(", ")}
        </Typography>
        <Button variant="outlined" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </CardContent>
    </Card>
  );
};

export const createCameraGroupGridItem = (item: CameraGroupItem): GridItem => ({
  i: `c-${item.index}`,
  x: 1,
  y: Infinity,
  w: 2,
  h: 2,
  component: <CameraGroupCard item={item} />
});

const FirmwareScheduleCard: React.FC<{ item: FirmwareScheduleItem }> = ({
  item
}) => {
  const { deleteFirmwareSchedule } = useFleetAPI();

  const handleDelete = async () => {
    try {
      await deleteFirmwareSchedule({ index: item.index });
    } catch (error) {
      console.error("Failed to delete firmware schedule:", error);
    }
  };

  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          Schedule Index: {item.index}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {item.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Description: {item.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Scheduled Date: {format(new Date(item.date_execute), "PPPp")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Camera Groups: {item.camera_group.join(", ")}
        </Typography>
        <Button variant="outlined" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </CardContent>
    </Card>
  );
};

export const createFirmwareScheduleGridItem = (
  item: FirmwareScheduleItem
): GridItem => ({
  i: `s-${item.index}`,
  x: 1,
  y: Infinity,
  w: 3,
  h: 3,
  component: <FirmwareScheduleCard item={item} />
});

export const createMapGridItem = (): GridItem => ({
  i: `map-${Date.now()}`,
  x: 0,
  y: 0,
  w: 6,
  h: 6,
  component: <MapWidget />
});

export const createTableGridItem = (): GridItem => {
  const id = `table-${Date.now()}`;
  return {
    i: id,
    x: 0,
    y: 0,
    w: 10,
    h: 4,
    component: <TableWidget id={id} />
  };
};

// export const createCameraGridItem = (
//   id: string,
//   remove: () => void
// ): GridItem => ({
//   i: `camera-${Date.now()}`,
//   x: 0,
//   y: 0,
//   w: 3,
//   h: 4,
//   component: <CameraWidget id={id} remove={remove} />
// });

function loadItemsFromLocalStorage(): GridItem[] {
  try {
    const savedItems = localStorage.getItem("fleetDashboardItems");
    const parsedItems = savedItems ? JSON.parse(savedItems) : [];

    return parsedItems.map(
      (item: {
        i: string;
        x: number;
        y: number;
        w: number;
        h: number;
        widgetData: any;
      }) => {
        const widgetComponent = (
          <TableWidget id={item.i} config={item.widgetData} />
        );

        return {
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          component: widgetComponent,
          widgetData: item.widgetData
        } as GridItem;
      }
    );
  } catch (error) {
    console.error("Failed to parse saved items:", error);
  }
  return [];
}

function serializeItems(items: GridItem[]) {
  return items.map(item => ({
    i: item.i,
    x: item.x,
    y: item.y,
    w: item.w,
    h: item.h,
    widgetData: item.widgetData
  }));
}

interface FleetDashboardContextType {
  items: GridItem[];
  addCard: (params: AddCardParams) => void;
  addItem: () => void;
  removeItem: (id: string) => void;
  updateLayout: (layout: Layout[]) => void;
  updateItem: (updatedItem: GridItem) => void;
  updateWidgetData: (id: string, widgetData: any) => void;
}

const FleetDashboardContext = createContext<
  FleetDashboardContextType | undefined
>(undefined);

export const FleetDashboardProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [items, setItems] = useState<GridItem[]>(() =>
    loadItemsFromLocalStorage()
  );
  const [newCounter, setNewCounter] = useState(5);
  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
  const fleetDashboard = useFleetDashboardAPI();
  const { sessionUser } = useAuth();

  useEffect(() => {
    const loadItems = async () => {
      if (sessionUser?.customer_id && sessionUser?.username) {
        try {
          const getWidgetData = await fleetDashboard.getWidgetData(
            sessionUser.customer_id,
            sessionUser.username
          );

          if (getWidgetData && Array.isArray(getWidgetData)) {
            const loadedItems = getWidgetData.map(item => {
              const widgetComponent = (
                <TableWidget id={item.i} config={item.widgetData} />
              );

              return {
                i: item.i,
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                component: widgetComponent,
                widgetData: item.widgetData
              } as GridItem;
            });

            setItems(loadedItems);
          }
        } catch (error) {
          console.error("Failed to load widget data:", error);
        }
      }
    };
    loadItems();
  }, [sessionUser, fleetDashboard]);

  const addCard = ({ type, data }: AddCardParams) => {
    setItems(prevItems => {
      let newItem: GridItem;

      switch (type) {
        case "firmware":
          newItem = createFleetGridItem(data as FleetItem);
          break;
        case "cameraGroup":
          newItem = createCameraGroupGridItem(data);
          break;
        case "firmwareSchedule":
          newItem = createFirmwareScheduleGridItem(data);
          break;
        case "map":
          newItem = createMapGridItem();
          break;
        case "table":
          newItem = createTableGridItem();
          break;
        case "camera":
          newItem = cameraGridItem();
          break;
        default:
          return prevItems;
      }
      newItem.x = (prevItems.length * 2) % (cols.lg || 12);
      return [...prevItems, newItem];
    });
  };

  const removeItem = (id: string) => {
    setItems(prevItems => prevItems.filter(item => item.i !== id));
  };

  const updateLayout = (layout: Layout[]) => {
    const newItems = layout.map(item => {
      const existingItem = items.find(l => l.i === item.i);
      return existingItem
        ? ({ ...existingItem, ...item } as GridItem)
        : (item as GridItem);
    });
    setItems(newItems);
  };

  const addItem = () => {
    setItems(prevItems => {
      const newItem = {
        i: "n" + newCounter,
        x: (items.length * 2) % (cols.lg || 12),
        y: Infinity,
        w: 2,
        h: 2,
        component: <></>
      };
      return [...prevItems, newItem];
    });
    setNewCounter(newCounter + 1);
  };

  const updateItem = (updatedItem: GridItem) => {
    setItems(prevItems =>
      prevItems.map(item => (item.i === updatedItem.i ? updatedItem : item))
    );
  };

  const updateWidgetData = (id: string, widgetData: any) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.i === id
          ? {
              ...item,
              widgetData
            }
          : item
      )
    );
  };

  useEffect(() => {
    const serializedItems = serializeItems(items);
    localStorage.setItem(
      "fleetDashboardItems",
      JSON.stringify(serializedItems)
    );

    const saveToAPI = async () => {
      if (sessionUser) {
        try {
          await fleetDashboard.setWidgetData(
            sessionUser.customer_id,
            sessionUser.username,
            serializedItems
          );
        } catch (error) {
          console.error("Failed to save dashboard data to API:", error);
        }
      }
    };

    saveToAPI();
  }, [items, sessionUser]);

  return (
    <FleetDashboardContext.Provider
      value={{
        items,
        addCard,
        addItem,
        removeItem,
        updateLayout,
        updateItem,
        updateWidgetData
      }}
    >
      {children}
    </FleetDashboardContext.Provider>
  );
};

export const useFleetDashboard = () => {
  const context = useContext(FleetDashboardContext);
  if (!context) {
    throw new Error(
      "useFleetDashboard must be used within a FleetDashboardProvider"
    );
  }
  return context;
};
