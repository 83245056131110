import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import TableListEndpoints from "pages/FleetDashboard/Widgets/TableWidget/TableEndpoints";

type UseTableAPIType<T> = {
  listByCustomer: (
    customerId: string,
    endpoint: TableListEndpoints,
    page?: number,
    pageSize?: number
  ) => Promise<{
    data: T[];
    total: number;
  }>;
};

export const SERVER_PAGE_SIZE = 100;

const useTableAPI = <T>(): UseTableAPIType<T> => {
  const API = useAxios();

  const listByCustomer = async (
    customerId: string,
    endpoint: TableListEndpoints,
    page = 1
  ): Promise<{ data: T[]; total: number }> => {
    const response: AxiosResponse<{ data: T[]; total?: number }> =
      await API.instance.post(endpoint, {
        ["customer_id"]: customerId,
        ["page"]: page,
        ["page_size"]: SERVER_PAGE_SIZE
      });

    const values = Object.values(response.data.data);
    const firstArrayValue = values.find(value => Array.isArray(value));

    return {
      data: (firstArrayValue as T[]) || [],
      total: response.data.total || (firstArrayValue as T[])?.length || 0
    };
  };

  return {
    listByCustomer
  };
};

export default useTableAPI;
