import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";

type UseFleetDashboardAPIType = {
  getWidgetData: (customerId: string, username: string) => Promise<any>;
  setWidgetData: (
    customerId: string,
    username: string,
    widgetData: any[]
  ) => Promise<any>;
};

export const SERVER_PAGE_SIZE = 100;

const useFleetDashboardAPI = (): UseFleetDashboardAPIType => {
  const API = useAxios();

  const getWidgetData = async (
    customerId: string,
    username: string
  ): Promise<any> => {
    const response: AxiosResponse<any> = await API.instance.get("widget", {
      params: {
        ["customer_id"]: customerId,
        username: username
      }
    });

    return response.data;
  };

  const setWidgetData = async (
    customerId: string,
    username: string,
    widgetData: any[]
  ): Promise<any> => {
    const response: AxiosResponse<any> = await API.instance.post("widget/set", {
      customer_id: customerId,
      username: username,
      widget_data: widgetData
    });

    return response.data;
  };

  return {
    getWidgetData,
    setWidgetData
  };
};
export default useFleetDashboardAPI;
