import {
  Box,
  Grid,
  Radio,
  styled,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  Autocomplete,
  FormControlLabel,
  Tooltip
} from "@mui/material";
import { FC, useState } from "react";
import Drawer from "components/Drawer";
import Button from "components/Button";
import FormLabel from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import {
  addDays,
  subDays,
  differenceInDays,
  startOfMonth,
  startOfDay,
  endOfDay,
  endOfMonth
} from "date-fns";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormCameraDashboardFilter = {
  startDate: string | Date;
  endDate: string | Date;
  monthDate: string | Date;
  grouping: string;
  camera: string;
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  filter: FormCameraDashboardFilter;
  setFilter: (filter: FormCameraDashboardFilter) => void;
  isLoading: boolean;
  cameras: string[];
};

const CameraDashboardFilter: FC<Props> = ({
  open,
  setOpen,
  filter,
  setFilter,
  isLoading,
  cameras
}) => {
  const { t } = useTranslation();
  const [selectedCamera, setSelectedCamera] = useState("");
  const [grouping, setGrouping] = useState(filter.grouping);
  const defaultValues: FormCameraDashboardFilter = {
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    monthDate: new Date(),
    camera: "",
    grouping: "week"
  };

  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm<FormCameraDashboardFilter>({
      mode: "onChange",
      defaultValues
    });

  const clear = () => {
    reset(defaultValues);
    setValue("startDate", defaultValues.startDate);
    setValue("endDate", defaultValues.endDate);
    setValue("monthDate", defaultValues.monthDate);
  };

  const onSubmit = async (data: FormCameraDashboardFilter) => {
    const newData = { ...data, grouping };
    newData.camera = selectedCamera;
    if (grouping === "month") {
      const month = getValues("monthDate");
      const firstDateOfMonth = startOfDay(startOfMonth(month as Date));
      newData.startDate = firstDateOfMonth;
      newData.endDate = endOfDay(endOfMonth(month as Date));
      newData.monthDate = newData.endDate;
    } else {
      newData.startDate = startOfDay(newData.startDate as Date);
      newData.endDate = endOfDay(newData.endDate as Date);
      if (differenceInDays(newData.endDate, newData.startDate) > 7) {
        newData.endDate = addDays(newData.startDate, 7);
      }
      newData.monthDate = newData.endDate;
    }

    setFilter(newData);
    setOpen(false);
  };

  return (
    <>
      <Drawer
        open={open}
        setOpen={setOpen}
        title={`${t("MonitoredVehiclesDashboardPage.filter")} ${t(
          `CameraDashboardPage.by${grouping.toLowerCase()}`
        )}`}
      >
        <Divider sx={{ mb: 3 }} />
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Content>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <FormLabel>{t("CameraDashboardPage.grouping")}</FormLabel>
                <RadioGroup
                  row
                  value={grouping}
                  onChange={event => setGrouping(event.target.value)}
                >
                  <FormControlLabel
                    value="week"
                    control={<Radio />}
                    label={t("CameraDashboardPage.byweek")}
                  />
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    label={t("CameraDashboardPage.bymonth")}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ mb: 2, display: grouping === "week" ? "grid" : "none" }}
            >
              <Grid item xs={12}>
                <Controller
                  name="startDate"
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      required
                      disabled={isLoading}
                      field={{ ...field }}
                      fieldState={fieldState}
                      label={t("RestrictionsPage.startDateTime")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="endDate"
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      required
                      disabled={isLoading}
                      field={{ ...field }}
                      fieldState={fieldState}
                      label={t("RestrictionsPage.endDateTime")}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  align="left"
                  color="primary.light"
                >
                  {t("CameraDashboardPage.tooltipRangeDate")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ display: grouping === "month" ? "grid" : "none" }}
            >
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Controller
                  name="monthDate"
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      required
                      disabled={isLoading}
                      field={{ ...field }}
                      fieldState={fieldState}
                      views={["month", "year"]}
                      label={t("RestrictionsPage.startDateTime")}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormLabel required>{t("CameraDashboardPage.cam")}</FormLabel>
              <Autocomplete
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={cameras}
                disabled={isLoading}
                getOptionLabel={option => option}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  event.preventDefault();
                  setSelectedCamera(newValue || "");
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedCamera}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t("CameraDashboardPage.camHint")}
                  />
                )}
                renderOption={(props, option) => (
                  <Tooltip title={option} arrow placement="right">
                    <li {...props}>{option}</li>
                  </Tooltip>
                )}
              />
            </Grid>
          </Content>
          <Footer>
            <Button
              customProps={{
                color: "primary",
                variant: "outlined",
                onClick: () => clear()
              }}
            >
              {t("action.clear")}
            </Button>
            <Button
              customProps={{
                disabled:
                  !formState.isValid || selectedCamera === "" || isLoading,
                type: "submit"
              }}
            >
              {t("action.filter")}
            </Button>
          </Footer>
        </form>
      </Drawer>
    </>
  );
};

export default CameraDashboardFilter;
