import { useFleetDashboard } from "../../FleetDashboardContext";
import {
  Box,
  Card,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
  Icon,
  TextField,
  CircularProgress,
  Typography,
  InputAdornment
} from "@mui/material";
import Pages from "enums/Pages";
import { orderBy } from "lodash";
import { format } from "date-fns";
import Order from "enums/OrderType";
import { Table } from "components/Table";
import { visuallyHidden } from "@mui/utils";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import {
  CSSProperties,
  FC,
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useState
} from "react";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import { Search, Settings, Download } from "react-feather";
import useTableAPI, { SERVER_PAGE_SIZE } from "./TableAPI";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import TableListEndpoints from "./TableEndpoints";
import {
  findTranslation,
  renderCellContent
} from "pages/FleetDashboard/FleetDashboardUtils";
export type tableColumn = {
  id: string;
  label: string;
  visible: boolean;
};

export type tableConfig = {
  endpoint: TableListEndpoints;
  label: string;
  columns: tableColumn[];
};

const centerStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  padding: "4px 20px",
  width: "100%",
  textAlign: "center"
};

const Subtitle = styled("h2")(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.light,
  textTransform: "uppercase",
  fontSize: "12px",
  fontWeight: 500
}));

const HeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 1
});

const TableContainer = styled(Box)({
  height: "100%",
  overflow: "auto",
  position: "relative",

  "& .MuiTable-root": {
    width: "100%",
    tableLayout: "fixed"
  },

  "& .MuiTableCell-root": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "fitContent"
  },

  "& .MuiTableHead-root": {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#fff"
  },

  "& .MuiTableHead-root .MuiTableCell-root": {
    boxShadow: "0 2px 2px rgba(0,0,0,0.05)"
  }
});

interface TableWidgetProps {
  id: string;
  config?: tableConfig;
}

const TableWidget: FC<TableWidgetProps> = ({ id, config }) => {
  const [selectedEndpoint, setSelectedEndpoint] = useState<
    TableListEndpoints | ""
  >(config?.endpoint || "");
  const [columns, setColumns] = useState<tableColumn[]>(config?.columns || []);
  const [isTableSelectDialogOpen, setTableSelectDialogOpen] = useState(!config);
  const [isColumnSelectDialogOpen, setColumnSelectDialogOpen] = useState(
    !config
  );
  const { updateWidgetData } = useFleetDashboard();
  const [fetching, setFetching] = useState(false);
  const { setLocation, setPageTitle } = usePageLocation();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [order, setOrder] = useState<Order>("asc");
  const [orderProperty, setOrderProperty] = useState<string>("location_name");
  const [customLabel, setCustomLabel] = useState<string>(config?.label || "");
  const TableAPI = useTableAPI<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [cachedData, setCachedData] = useState<any[]>([]);
  const [currentServerPage, setCurrentServerPage] = useState(1);
  const [filterText, setFilterText] = useState<string>("");
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDrop = (dropIndex: number) => {
    if (draggedItemIndex === null || draggedItemIndex === dropIndex) {
      return;
    }

    const newColumns = [...columns];
    const [draggedItem] = newColumns.splice(draggedItemIndex, 1);
    newColumns.splice(dropIndex, 0, draggedItem);
    setColumns(newColumns);
  };

  const getFilteredData = useCallback(() => {
    if (!filterText.trim()) {
      return tableData;
    }

    const lowercasedFilter = filterText.toLowerCase();
    return tableData.filter(row =>
      columns
        .filter(col => col.visible)
        .some(column => {
          const cellValue = renderCellContent(column.id, row);
          return cellValue.toLowerCase().includes(lowercasedFilter);
        })
    );
  }, [tableData, filterText, columns]);

  const requestData = useCallback(
    async (forceRefresh = false) => {
      if (
        !sessionUser?.["customer_id"] ||
        !selectedEndpoint ||
        isColumnSelectDialogOpen
      )
        return;

      try {
        setFetching(true);

        const neededServerPage =
          Math.floor((page * rowsPerPage) / SERVER_PAGE_SIZE) + 1;

        if (
          forceRefresh ||
          neededServerPage !== currentServerPage ||
          cachedData.length === 0
        ) {
          const result = await TableAPI.listByCustomer(
            sessionUser["customer_id"],
            selectedEndpoint,
            neededServerPage
          );

          const newCachedData = result.data;
          setCachedData(newCachedData);
          setTotalRows(result.total);
          setCurrentServerPage(neededServerPage);

          if (result.data.length > 0 && !columns.length) {
            const firstRow = result.data[0];
            const newColumns: tableColumn[] = Object.keys(firstRow).map(
              key => ({
                id: key,
                label: findTranslation(key),
                visible: true
              })
            );
            setColumns(newColumns);
          }

          const startIndex = (page * rowsPerPage) % SERVER_PAGE_SIZE;
          const endIndex = Math.min(
            startIndex + rowsPerPage,
            newCachedData.length
          );
          const displayData = newCachedData.slice(startIndex, endIndex);

          setTableData(displayData);
        } else {
          const startIndex = (page * rowsPerPage) % SERVER_PAGE_SIZE;
          const endIndex = Math.min(
            startIndex + rowsPerPage,
            cachedData.length
          );
          const displayData = cachedData.slice(startIndex, endIndex);

          setTableData(displayData);
        }

        if (!orderProperty && cachedData.length > 0) {
          setOrderProperty(Object.keys(cachedData[0])[0]);
        }

        setOrder("asc");
      } catch (error) {
        errorHandler({ error });
      } finally {
        setFetching(false);
      }
    },
    [
      page,
      rowsPerPage,
      sessionUser,
      selectedEndpoint,
      isColumnSelectDialogOpen,
      currentServerPage,
      cachedData,
      orderProperty
    ]
  );

  useEffect(() => {
    requestData();
  }, [requestData, page, rowsPerPage]);

  useEffect(() => {
    setPageTitle(t("windowTitle.equipmentMap"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("EquipmentMapPage.title"),
        page: Pages.EQUIPMENT_MAP
      }
    ]);
  }, [t, Pages]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderProperty === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderProperty(property);

    const isNumeric =
      cachedData.length > 0 &&
      (typeof cachedData[0][property] === "number" ||
        (typeof cachedData[0][property] === "string" &&
          !isNaN(Number(cachedData[0][property]))));

    const sortedCachedData = orderBy(
      cachedData,
      [
        row => {
          if (isNumeric) {
            return typeof row[property] === "number"
              ? row[property]
              : Number(row[property]);
          }
          return row[property] === null ? "" : row[property];
        }
      ],
      [isAsc ? "desc" : "asc"]
    );

    setCachedData(sortedCachedData);
    const startIndex = (page * rowsPerPage) % SERVER_PAGE_SIZE;
    const endIndex = Math.min(
      startIndex + rowsPerPage,
      sortedCachedData.length
    );
    const newPageData = sortedCachedData.slice(startIndex, endIndex);
    setTableData(newPageData);
  };

  const handleColumnDialog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const newLabel = formData.get("tableLabel")?.toString().trim();
    setCustomLabel(
      newLabel ||
        Object.entries(TableListEndpoints).find(
          ([_, value]) => value === selectedEndpoint
        )?.[0] ||
        selectedEndpoint
    );

    const updatedColumns = columns.map(col => {
      const isVisible = formData.get(`visible-${col.id}`) === "on";
      const newLabel =
        formData.get(`label-${col.id}`)?.toString().trim() || col.label;
      return {
        ...col,
        visible: isVisible,
        label: newLabel
      };
    });

    updateWidgetData(id, {
      endpoint: selectedEndpoint,
      label: newLabel,
      columns: updatedColumns
    });

    setColumns(updatedColumns);
    setColumnSelectDialogOpen(false);
  };

  const handleEndpointSelection: FormEventHandler<
    HTMLFormElement
  > = async event => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const endpointValue = formData.get("selectedEndpoint") as
      | TableListEndpoints
      | "";

    if (!endpointValue) {
      return;
    }

    const tableLabel =
      Object.entries(TableListEndpoints).find(
        ([_, value]) => value === endpointValue
      )?.[0] || "";
    setCustomLabel(findTranslation(tableLabel) || tableLabel);

    try {
      setFetching(true);
      const result = await TableAPI.listByCustomer(
        sessionUser?.customer_id ?? "",
        endpointValue
      );

      if (result.data.length > 0) {
        const firstRow = result.data[0];
        const newColumns = Object.keys(firstRow).map(key => ({
          id: key,
          label: findTranslation(key),
          visible: true
        }));
        setColumns(newColumns);
      }

      setTableData(result.data);
      setTotalRows(result.total);
      setSelectedEndpoint(endpointValue);
      setTableSelectDialogOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setFetching(false);
    }
  };

  const handleExportCSV = () => {
    const visibleColumns = columns.filter(col => col.visible);
    const headerRow = visibleColumns.map(col => `"${col.label}"`).join(",");
    let dataToExport = cachedData;

    if (filterText.trim()) {
      const lowercasedFilter = filterText.toLowerCase();
      dataToExport = cachedData.filter(row =>
        visibleColumns.some(column => {
          const cellValue = renderCellContent(column.id, row);
          return cellValue.toLowerCase().includes(lowercasedFilter);
        })
      );
    }

    const dataRows = dataToExport.map(row =>
      visibleColumns
        .map(col => {
          const content = renderCellContent(col.id, row);
          return `"${content.replace(/"/g, "\"\"")}"`;
        })
        .join(",")
    );

    const csvContent = [headerRow, ...dataRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const date = format(new Date(), "yyyy-MM-dd");
    const filename = `${customLabel || selectedEndpoint}_${date}.csv`;
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Box
          mt={3}
          sx={{
            height: "100%"
          }}
        >
          <HeaderContainer style={centerStyle}>
            <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
              <TextField
                size="small"
                placeholder={t("common.filter")}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  )
                }}
                sx={{
                  maxWidth: "200px",
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                    fontSize: "0.875rem"
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <Subtitle>{customLabel}</Subtitle>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={t("common.previousPage")}>
                <IconButton
                  size="small"
                  onClick={() => setPage(Math.max(0, page - 1))}
                  disabled={page === 0}
                >
                  <Icon
                    component={() => (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 18L9 12L15 6"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  />
                </IconButton>
              </Tooltip>
              <Typography variant="caption" sx={{ mx: 1 }}>
                {page + 1} / {Math.max(1, Math.ceil(totalRows / rowsPerPage))}
              </Typography>
              <Tooltip title={t("common.nextPage")}>
                <IconButton
                  size="small"
                  onClick={() => setPage(page + 1)}
                  disabled={page >= Math.ceil(totalRows / rowsPerPage) - 1}
                >
                  <Icon
                    component={() => (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("common.selectColumns")}>
                <IconButton
                  size="small"
                  onClick={() => setColumnSelectDialogOpen(true)}
                >
                  <Icon component={Settings} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("common.exportCSV")}>
                <IconButton
                  size="small"
                  onClick={handleExportCSV}
                  disabled={fetching || tableData.length === 0}
                >
                  <Icon component={Download} />
                </IconButton>
              </Tooltip>
            </Box>
          </HeaderContainer>
          <Box
            mt={3}
            sx={{
              height: "100%"
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent"
              }}
            >
              <TableContainer
                sx={{
                  height: "100%",
                  position: "relative"
                }}
              >
                {fetching && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      zIndex: 3
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Table
                  sx={{
                    height: "100%"
                  }}
                >
                  <TableHead
                    sx={{
                      "& .MuiTableCell-root": {
                        whiteSpace: "nowrap"
                      }
                    }}
                  >
                    <TableRow>
                      {columns
                        .filter(col => col.visible)
                        .map(column => (
                          <TableCell
                            key={column.id}
                            component="th"
                            sortDirection={
                              orderProperty === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderProperty === column.id}
                              direction={
                                orderProperty === column.id ? order : "asc"
                              }
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {t(column.label)}
                              {orderProperty === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ paddingBottom: "100px" }}>
                    {getFilteredData().map((row, index) => (
                      <TableRow
                        hover
                        key={`${index}-${row.id || index}`}
                        sx={{ cursor: "pointer" }}
                      >
                        {columns
                          .filter(col => col.visible)
                          .map(column => {
                            const cellContent = renderCellContent(
                              column.id,
                              row
                            );
                            return (
                              <Tooltip key={column.id} title={cellContent}>
                                <TableCell key={column.id}>
                                  <span>{cellContent}</span>
                                </TableCell>
                              </Tooltip>
                            );
                          })}
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        pointerEvents: "none",
                        height: "100px"
                      }}
                    ></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
        </Box>
      </Card>
      <Dialog
        open={isTableSelectDialogOpen}
        onClose={() => setTableSelectDialogOpen(false)}
      >
        <DialogTitle>{t("common.selectTable")}</DialogTitle>
        <form onSubmit={handleEndpointSelection}>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel>{t("common.selectTable")}</InputLabel>
              <Select
                label={t("common.selectTable")}
                name="selectedEndpoint"
                defaultValue={selectedEndpoint}
              >
                {Object.entries(TableListEndpoints).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {t(key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" disabled={fetching}>
              {fetching ? <CircularProgress size={20} /> : t("common.confirm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isColumnSelectDialogOpen && columns.length > 0}
        onClose={() => setColumnSelectDialogOpen(false)}
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
            maxWidth: "1200px"
          }
        }}
      >
        <DialogTitle>{t("common.tableSettings")}</DialogTitle>
        {isColumnSelectDialogOpen && (
          <form onSubmit={handleColumnDialog}>
            <DialogContent>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" gutterBottom>
                  {t("common.tableLabel")}
                </Typography>
                <TextField
                  key={`table-label-${isColumnSelectDialogOpen}-${customLabel}`}
                  name="tableLabel"
                  variant="outlined"
                  fullWidth
                  defaultValue={customLabel}
                  InputLabelProps={{ shrink: false }}
                  label=""
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" gutterBottom>
                  {t("common.rowsPerPage")}
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={rowsPerPage}
                    onChange={e => {
                      setRowsPerPage(Number(e.target.value));
                      setPage(0);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": t("common.rowsPerPage") }}
                  >
                    {[5, 10, 25, 50].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="5%"></TableCell>
                    <TableCell width="15%">{t("common.visible")}</TableCell>
                    <TableCell width="40%">{t("common.fieldName")}</TableCell>
                    <TableCell width="40%">
                      {t("common.displayLabel")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {columns.map((col, index) => (
                    <TableRow
                      key={col.id}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragOver={e => e.preventDefault()}
                      onDrop={e => {
                        e.preventDefault();
                        handleDrop(index);
                      }}
                      sx={{
                        "&:hover": { bgcolor: "rgba(0,0,0,0.04)" },
                        cursor: "move"
                      }}
                      data-position={index}
                    >
                      <TableCell>
                        <IconButton
                          size="small"
                          sx={{ cursor: "grab" }}
                          onMouseDown={e => e.stopPropagation()}
                        >
                          <Icon
                            component={() => (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 6H15M9 12H15M9 18H15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            )}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          name={`visible-${col.id}`}
                          defaultChecked={col.visible}
                        />
                      </TableCell>
                      <TableCell>{t(col.id)}</TableCell>
                      <TableCell>
                        <TextField
                          name={`label-${col.id}`}
                          variant="outlined"
                          size="small"
                          defaultValue={col.label}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained">
                {t("common.confirm")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default TableWidget;
