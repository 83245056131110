import { useCallback, useEffect, useState, FC, CSSProperties } from "react";
import {
  Card,
  styled,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import CameraView from "components/CameraView";
import useMosaicAPI, { Mosaic } from "api/MosaicAPI";
import { Icon } from "@mui/material";
import { Video } from "react-feather";
import { GridItem } from "pages/FleetDashboard/FleetDashboardContext";

interface CameraWidgetProps {
  id: string;
  remove: () => void;
}

const Title = styled("h1")(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.light,
  fontSize: "16px",
  fontWeight: 500
}));

const centerStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  padding: "4px 20px",
  width: "100%",
  textAlign: "center"
};

const CameraWidget: FC<CameraWidgetProps> = props => {
  const { id } = props;
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const MosaicAPI = useMosaicAPI();

  const [isRequestingData, setRequestingData] = useState(false);
  const [availableCameras, setAvailableCameras] = useState<
    Mosaic["mosaicData"]["mosaic_data"]
  >([]);
  const [firstCamera, setFirstCamera] = useState<
    Mosaic["mosaicData"]["mosaic_data"][0] | null
  >(null);
  const [selectedCameraId, setSelectedCameraId] = useState("");
  const [isSelectDialogOpen, setSelectDialogOpen] = useState(false);

  const requestData = useCallback(async () => {
    if (!sessionUser?.customer_id) return;
    setRequestingData(true);

    try {
      const response = await MosaicAPI.getByUsername({
        customerId: sessionUser.customer_id,
        username: sessionUser.username
      });

      const updated = response.mosaicData.mosaic_data.map((cam, index) => ({
        ...cam,
        id: cam.id && cam.id.length > 0 ? cam.id : String(index)
      }));
      setAvailableCameras(updated);

      if (!selectedCameraId && updated.length > 0) {
        setSelectDialogOpen(true);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setRequestingData(false);
    }
  }, []);

  useEffect(() => {
    if (!sessionUser?.customer_id || selectedCameraId) return;
    requestData();
  }, []);

  const handleCameraSelect = (cameraId: string) => {
    setSelectedCameraId(cameraId);
    const selected = availableCameras.find(cam => cam.id === cameraId);
    setFirstCamera(selected || null);
    setSelectDialogOpen(false);
  };

  return (
    <div
      key={id}
      style={{
        height: "100%",
        position: "relative"
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "transparent"
        }}
      >
        <CardHeader
          sx={{
            "& .MuiCardHeader-subheader": {
              width: "100%",
              display: "block"
            }
          }}
          title={
            <Title style={centerStyle}>
              {isRequestingData && (
                <CircularProgress size={16} sx={{ mr: 1 }} />
              )}
              {!isRequestingData && (
                <>
                  {firstCamera
                    ? firstCamera.camera
                    : t("CameraWidget.selectCamera")}
                </>
              )}
            </Title>
          }
          subheader={
            firstCamera && (
              <div style={{ width: "100%" }}>
                <IconButton
                  aria-label="change-camera"
                  onClick={() => setSelectDialogOpen(true)}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  <Icon component={Video} fontSize="small" />
                </IconButton>
                {firstCamera.location} - {firstCamera.owner}
              </div>
            )
          }
        />
        <div style={{ flexGrow: 1, position: "relative" }}>
          {firstCamera && (
            <CameraView
              image={firstCamera.image || ""}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute"
              }}
            />
          )}
        </div>
      </Card>

      {isSelectDialogOpen && (
        <Dialog
          open={isSelectDialogOpen}
          onClose={() => setSelectDialogOpen(false)}
        >
          <DialogTitle>{t("CameraWidget.selectCamera")}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <Select
                value={selectedCameraId}
                onChange={e => handleCameraSelect(e.target.value as string)}
                sx={{ marginBottom: "32px" }}
              >
                {availableCameras.map(camera => (
                  <MenuItem key={camera.id} value={camera.id}>
                    {camera.camera}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
const defaultLayout = {
  x: 0,
  y: 0,
  w: 3,
  h: 4
};

const createCameraGridItem = (id: string, remove: () => void): GridItem => ({
  i: id,
  ...defaultLayout,
  component: <CameraWidget id={id} remove={remove} />
});

export const cameraGridItem = (): GridItem =>
  createCameraGridItem(`camera-${Date.now()}`, () => {});

export default CameraWidget;
