import { Card, styled, CardHeader, CircularProgress } from "@mui/material";
import Map from "./Map";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import useMapsAPI, { EquipmentLocation } from "api/MapsAPI";
import EquipmentDetails from "./EquipmentDetails";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import {
  createRef,
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useState
} from "react";

const statusColors = {
  disabled: "#0b2Bfc",
  online: "#4caf50",
  error: "#ffce09",
  offline: "#ef5350"
};

const Title = styled("h1")(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.light,
  fontSize: "16px",
  fontWeight: 500
}));

const centerStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  padding: "4px 20px",
  width: "100%",
  textAlign: "center"
};

const MapWidget: FC = () => {
  const MapsAPI = useMapsAPI();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [isRequestingData, setRequestingData] = useState<boolean>(false);
  const [equipments, setEquipments] = useState<EquipmentLocation[]>([]);
  const { errorHandler } = useErrorHandler();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalEquipment, setModalEquipment] =
    useState<EquipmentLocation | null>(null);
  const [mapKey, setMapKey] = useState(0);

  const validateEquipments = (equipmentApiReturn: EquipmentLocation[]) => {
    const validEquipment = equipmentApiReturn.map(equipment => {
      const test: EquipmentLocation = {
        ...equipment,
        latitude: Number(equipment.latitude) || 0,
        longitude: Number(equipment.longitude) || 0
      };
      return test;
    });
    return validEquipment;
  };

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setRequestingData(true);
    try {
      const equipmentApiReturn = await MapsAPI.listAllByCustomer(
        sessionUser["customer_id"]
      );
      setEquipments([...validateEquipments(equipmentApiReturn)]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setRequestingData(false);
    }
  }, []);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const openModal = (equipment: EquipmentLocation) => {
    setModalEquipment(equipment);
    setModalOpen(true);
  };

  const mapContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setMapKey(prev => prev + 1);
  }, [isModalOpen, modalEquipment]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "transparent"
        }}
      >
        <CardHeader
          title={
            <Title style={centerStyle}>
              {isRequestingData && (
                <CircularProgress size={16} sx={{ mr: 1 }} />
              )}
              {t("SystemPageLayout.equipmentMap")}
            </Title>
          }
        />
        <div ref={mapContainerRef} style={{ width: "100%", flexGrow: 1 }}>
          {equipments.length > 0 && (
            <Map
              key={mapKey}
              geolocations={equipments.map(equipment => ({
                lat: Number(equipment.latitude),
                lng: Number(equipment.longitude),
                style: "equipment",
                onClick: () => {
                  openModal(equipment);
                },
                color: statusColors[equipment.status]
              }))}
              initialCenter={
                isModalOpen && modalEquipment
                  ? {
                      lat: modalEquipment.latitude,
                      lng: modalEquipment.longitude
                    }
                  : undefined
              }
            />
          )}
        </div>
      </Card>
      {isModalOpen && modalEquipment && (
        <EquipmentDetails
          onClose={() => setModalOpen(false)}
          equipment={modalEquipment}
          statusColor={statusColors[modalEquipment.status]}
        />
      )}
    </>
  );
};

export default MapWidget;
