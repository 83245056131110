import { getI18n } from "react-i18next";
import { format } from "date-fns";

export const findTranslation = (term: string): string => {
  const i18n = getI18n();

  if (!i18n || !i18n.options || !i18n.language) {
    // Return a formatted version of the term if i18n is not ready
    const words = term.split("_");
    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  }

  const caseTerm = term.replace(/_([a-z])/g, (_, letter) =>
    letter.toUpperCase()
  );
  const currentLanguageResources =
    i18n.options?.resources?.[i18n.language].translations;

  if (!currentLanguageResources) {
    return caseTerm;
  }
  const resources = currentLanguageResources as Record<
    string,
    Record<string, string>
  >;

  for (const key in resources) {
    if (Object.prototype.hasOwnProperty.call(resources, key)) {
      const translated = resources[key][caseTerm]
        ? resources[key][caseTerm]
        : resources[key][term];
      if (translated && !translated.includes("$t(")) {
        return translated;
      }
    }
  }

  const words = term.split("_");
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(" ");
};

export const renderCellContent = (
  columnId: string,
  rowData: Record<string, any>
): string => {
  const value = rowData[columnId];
  if (value === null || value === undefined) {
    return "‒";
  }

  let t = (key: string) => key;
  try {
    const i18n = getI18n();
    if (i18n && i18n.isInitialized && typeof i18n.t === "function") {
      const safeT = (key: string) => {
        try {
          return i18n.t(key);
        } catch {
          return key;
        }
      };
      t = safeT;
    }
  } catch (error) {
    console.warn("Translation function not available:", error);
  }

  const isValidDate = (str: string) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}|^\d{4}\/\d{2}\/\d{2}/;
    if (!datePattern.test(str)) return false;
    const parsed = new Date(str);
    return (
      parsed instanceof Date &&
      !isNaN(parsed.getTime()) &&
      parsed.getFullYear() > 1970
    );
  };

  if (typeof value === "string" && isValidDate(value)) {
    return format(new Date(value), t("form.dateFormat"));
  }

  const getDisplayProperty = (item: any): string => {
    if (!item || typeof item !== "object") return String(item);

    const propertyNames = Object.keys(item);
    const displayPropertyName = propertyNames.find(
      prop =>
        prop.toLowerCase().includes("label") ||
        prop.toLowerCase().includes("name") ||
        prop.toLowerCase().includes("title") ||
        propertyNames[0]
    );

    if (displayPropertyName) {
      return String(item[displayPropertyName]);
    }

    return "-";
  };

  if (Array.isArray(value)) {
    if (typeof value[0] === "object") {
      return value.map(item => getDisplayProperty(item)).join(", ");
    }
    return value.join(", ");
  }

  if (Array.isArray(value) || typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }

  if (typeof value === "boolean") {
    return value ? t("common.yes") : t("common.no");
  }

  return String(value);
};
