enum Pages {
  BILLING = "/settings/plan-payment",
  CAMERAS = "/system/cameras",
  CAMERA_DASHBOARD = "/dashboards/cameraDashboard",
  CAMERA_DASHBOARD_OLD = "/dashboards/cameraDashboardOld",
  CAPTURE_REPORT = "/reports/capture-report",
  CLONE_DASHBOARD = "/dashboards/cloneDashboard",
  CONVOY_DASHBOARD = "/dashboards/convoyDashboard",
  CONFIRM_USER = "/confirm-user",
  CONVOY_REPORT = "/reports/convoy-report",
  CORRELATION_REPORT = "/reports/correlation-report",
  CREATE_ACCOUNT = "/create-account",
  CUSTOMERS = "/settings/customers",
  CUSTOMIZATION = "/settings/customizations",
  EQUIPMENT_MAP = "/system/equipment-map",
  EQUIPMENTS = "/system/equipments",
  ERROR_403 = "/403",
  FLEET = "/dashboards/fleet",
  FLOW_DASHBOARD = "/dashboards/flowDashboard",
  FORENSIC_REPORT = "/reports/forensics-report",
  INCIDENT_REPORT = "/reports/incidents-report",
  INFORMATION_PROTECTION = "/settings/information-protection",
  INTEGRATIONS = "/settings/integrations",
  INVOICE = "/settings/invoices",
  LOG_REPORT = "/reports/log-report",
  MONITORED_ROUTES = "/system/routes",
  MONITORING_REPORT = "/reports/monitoring-report",
  MONITORING_VEHICLES = "/system/monitoring-vehicles",
  MONITORING_VEHICLES_DASHBOARD = "/dashboards/dashboard",
  MOSAIC = "/system/cameras-mosaic",
  MY_ACCOUNT = "/my-account",
  PROFILES = "/settings/profiles",
  RESTRICTION = "/system/restriction",
  RESET_PASSWORD = "/reset-password",
  RESTRICTION_DASHBOARD = "/dashboards/restrictionDashboard",
  SETTINGS = "/settings",
  SHARED_DATA = "/shared-data",
  SIGN_IN = "/sign-in",
  SPIA_REPORT = "/reports/spia-report",
  SYSTEM = "/system",
  SYSTEM_FILTER_CAMERAS = "/system/mosaic/filter-cameras",
  SYSTEM_MONITORING = "/system/monitoring",
  TERMS = "/terms",
  USER_GROUPS = "/settings/user-groups",
  USER_GUIDE = "/user-guide",
  USERS = "/settings/users",
  VEHICLE_OVERVIEW = "/vehicle-overview",
  VERTICALS = "/settings/verticals"
}

enum PageLabelsEnum {
  DASHBOARDS_MENU = "menu.dashboards",
  DASHBOARD = "menu.dashboard",
  MONITORING_VEHICLES_DASHBOARD = "menu.dashboard",
  FLOW_DASHBOARD = "menu.flowDashboard",
  CAMERA_DASHBOARD = "menu.cameraDashboard",
  CLONE_DASHBOARD = "menu.cloneDashboard",
  CONVOY_DASHBOARD = "menu.convoyDashboard",
  FLEET = "menu.fleetManagement",
  RESTRICTION_DASHBOARD = "menu.restrictionDashboard",
  SYSTEM_MENU = "menu.system",
  MONITORING_MENU = "menu.monitoring",
  MOSAIC = "CamerasMosaicPage.title",
  EQUIPMENT_MAP = "EquipmentMapPage.title",
  MONITORING_VEHICLES = "MonitoringVehicles.title",
  RESTRICTION = "RestrictionsPage.restrictionRecords",
  MONITORED_ROUTES = "MonitoredRoutesPage.title",
  EQUIPMENTANDCAMERA_MENU = "menu.equipmentsAndCameras",
  EQUIPMENTS = "EquipmentsPage.title",
  CAMERAS = "CamerasPage.title",
  REPORTS_MENU = "menu.reports",
  CAPTURE_REPORT = "CaptureReportPage.title",
  CONVOY_REPORT = "ConvoyReportPage.title",
  CORRELATION_REPORT = "CorrelationReportPage.title",
  MONITORING_REPORT = "MonitoringReportPage.title",
  SPIA_REPORT = "SpiaReportPage.title",
  LOG_REPORT = "LogReportPage.title",
  FORENSIC_REPORT = "ForensicsReportPage.title",
  INCIDENT_REPORT = "IncidentReportPage.title",
  SETTINGS_MENU = "menu.settings",
  ACCESSCONTROL_MENU = "menu.accessControl",
  USERS = "UsersPage.title",
  USER_GROUPS = "UserGroupsPage.title",
  PROFILES = "ProfilesPage.title",
  SHARED_DATA = "ShareDataPage.title",
  CUSTOMERS = "CustomerPage.title",
  VERTICALS = "VerticalsPage.title",
  BILLING_MENU = "menu.billing",
  BILLING = "BillingPage.title",
  INVOICE = "InvoicePage.title",
  CUSTOMIZATION_MENU = "SystemPageLayout.system",
  CUSTOMIZATION = "CustomizationPage.title",
  INTEGRATIONS = "IntegrationsPage.title",
  INFORMATION_PROTECTION = "InformationProtectionPage.title",
  USER_AGREEMENT = "menu.userAgreement",
  USER_GUIDE = "menu.userGuide"
}

export const ErrorPages: string[] = [Pages.ERROR_403];

export const PageLabels = PageLabelsEnum as { [key: string]: string };

export default Pages as { [key: string]: string };
