enum TableListEndpoints {
  RESTRICTION_LIST = "restriction/list",
  CAMERA_LIST = "camera/list",
  LOCATION_LIST = "location/list",
  USER_LIST = "user/list",
  USER_GROUP_LIST = "user-group/list"
  // {
  //   ["customer_id"]: customerId,
  //   page,
  //   ["page_size"]: pageSize
  // }
}

export default TableListEndpoints;
